<template>
<v-app>
    <v-app-bar app class="w-app-bar">
      <v-app-bar-nav-icon @click="drawer = !drawer" color="rgb(91, 56, 148)"></v-app-bar-nav-icon>

      <v-app-bar-title style="color: rgb(91, 56, 148);"><img src="https://wesen.b-cdn.net/logo_wesen.png?class=public" style="margin-top:5px;" alt="" width="70"></v-app-bar-title>

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu offset-overflow transition="slide-x-transition">
          <template v-slot:activator="{ on, attrs }">
              <v-btn icon
              v-bind="attrs"
              v-on="on"
              >
                  <v-avatar :color="authUser.enterprise.id_logo ? 'rgba(167,92,172,1)' : '#FFFFFF'"
                          style="border: 2px solid;">
                      <span v-if="!authUser.enterprise.id_logo" class="white--text text-h6">{{ initialsName }}</span>
                      <img v-if="authUser.enterprise.id_logo" :src="authUser.enterprise.logo" alt="">
                  </v-avatar>
              </v-btn>
          </template>
          <v-card
              class="mx-auto"
              width="400"
          >
              <v-list-item>
              <v-list-item-content>
                  <div class="mx-auto text-center">
                      <v-avatar
                          :color="authUser.enterprise.id_logo ? 'rgba(167,92,172,1)' : '#FFFFFF'"
                          style="border: 2px solid;"
                      >
                          <span v-if="!authUser.enterprise.id_logo" class="white--text text-h6">{{ initialsName }}</span>
                          <img v-if="authUser.enterprise.id_logo" :src="authUser.enterprise.logo" alt="">
                      </v-avatar>
                      <h3>{{ user.fullName }}</h3>
                      <p class="text-caption mt-1">
                          {{ user.email }}
                      </p>
                      <v-divider class="my-3"></v-divider>
                      <router-link :to="'/user'">
                      <v-btn
                          depressed
                          rounded
                          text
                      >
                          Perfil
                      </v-btn>
                      </router-link>
                      <v-divider class="my-3"></v-divider>
                      <v-btn
                          depressed
                          rounded
                          text
                          @click="logout"
                      >
                        Cerrar Sesión
                      </v-btn>
                  </div>
              </v-list-item-content>
              
              </v-list-item>
          </v-card>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer id="navigation-drawer"
      app
      v-model="drawer"
      temporary
      light
    >
      <v-list-item class="px-2 menu-list-item">
        <v-list-item-avatar :color="!authUser.enterprise.id_logo ? 'rgba(167,92,172,1)' : '#FFFFFF'">
          <span v-if="!authUser.enterprise.id_logo" class="white--text text-h7">{{ initialsName }}</span>
          <img v-if="authUser.enterprise.id_logo" :src="authUser.enterprise.logo" alt="">
        </v-list-item-avatar>

        <v-list-item-title>{{ user.fullName || '' }}</v-list-item-title>

        <v-btn
          icon
          @click="drawer = !drawer"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <div v-for="(item, i) in items" :key="i">
          <div v-if="!item.admin || item.admin == authUser.is_admin">
            <v-list-item
              v-if="!item.subLinks"
              v-bind:to="item.path"
              class="menu-tile"
              color="purple"
            >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            
            <v-list-group
              v-else
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(subLink, j) in item.subLinks"
                :key="j"
                :to="subLink.path"
                color="purple"
                class="menu-tile"
              >
                <v-list-item-icon>
                  <v-icon>{{ subLink.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ subLink.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <section>
            <v-scroll-x-transition mode="out-in" hide-on-leave>
                <router-view class="views-container"></router-view>
            </v-scroll-x-transition>
    </section>
    
    <v-footer padless>
        <v-card
        flat
        tile
        class="flex lighten-1 white--text text-center"
        color="rgb(33 14 64)"
        >

        <v-card-text class="white--text">
            Wesen | {{ new Date().getFullYear() }} — <strong>DIGITAL MENU</strong>
        </v-card-text>
        </v-card>
    </v-footer>
  </v-app>
</template>

<style>
  .w-app-bar{
    background: #ffffffba !important;
    backdrop-filter: blur(7px);
    box-shadow: 0px 0px 8px rgba(0,0,0,0.1), 0px 8px 16px rgba(51,51,51,0.16) !important;
    -webkit-box-shadow: 0px 0px 8px rgba(0,0,0,0.1), 0px 8px 16px rgba(51,51,51,0.16) !important;
  }
</style>

<style scoped>
  #navigation-drawer {
    border-radius: 15px;
    height: 95vh !important;
    top: 1rem !important;
    left: 1rem;
  }

  .views-container {
    min-height: 100vh;
  }

  /* .menu-tile {
    text-decoration: none;
    margin: 5px;
    border-radius: 4px;
  }
  .menu-tile:hover {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(229,206,230,1) 61%, rgba(167,92,172,1) 100%);
  }
  .menu-tile:active {
    opacity: 0 !important;
    background: rgba(167, 92, 172, 0.822);
  }

  .theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
        opacity: 0 !important;
    }

  .menu-tile.v-list-item--active {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #ffffff, rgba(167,92,172,1));
  }
  
  .menu-tile.v-list-item--active::after {
    border-radius: 4px;
  }
  .menu-list-item {
    padding-top: 2em;
    padding-bottom: 2em;
  } */

  .footer-text {
    padding: 2em !important;
  }
</style>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
      return {
          drawer: false,
          items: [
              { title: 'Home', icon: 'mdi-home-city', path: '/dashboard', admin: false},
              { title: 'Perfil', icon: 'mdi-account', path: '/user', admin: false },
              { title: 'Usuarios', icon: 'mdi-account-multiple', path: '/users', admin: true },
              { title: 'Administrador', icon: '', subLinks: [
                  { title: 'Secciones', icon: 'mdi-widgets', path: '/manager/sections', admin: false },
                  { title: 'Categorias', icon: 'mdi-room-service', path: '/manager/categories', admin: false },
                  { title: 'Productos', icon: 'mdi-food-turkey', path: '/manager/products', admin: false },
                ] 
              },
          ],
          mini: true,
          user: {
              fullName: '',
              email: '',
          },
      } 
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  mounted() {
    /* let authUser = this.$store;
    console.log(this.authUser); */
    
    this.user.fullName = this.authUser.name;
    this.user.email = this.authUser.email;
    /* this.user.fullName = authUser.name;
    this.user.email = authUser.email; */
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    /* ...mapGetters("auth", ["authUser", "isAdmin"]), */
    initialsName() {
      let initials = this.user.fullName.split(' ');
      if(initials.length > 2) {
        initials = initials[0].charAt(0) + initials[2].charAt(0);
      } else if(initials.length == 2){
        initials = initials[0].charAt(0) + initials[1].charAt(0);
      } else {
        initials = this.user.fullName.substring(0, 2);
      }
      
      return initials.toUpperCase();
    }
  },
}
</script>