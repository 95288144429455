export default function mustChangePassword({ to, next, store }) {
    if (!store.getters["auth/mustChangePassword"]){
        if(store.getters["auth/authUser"]){
            if(to.name == 'change-password'){
                next({name: 'dashboard'});
            }else{
                next();
            }
        }else{
            next({name: 'login'})
        }
    }
    else if (to.name != 'change-password'){
        next({name: 'change-password'});
    }else{
        next();
    } 
    //next();
  }
  