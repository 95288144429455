<template>
  <div id="app">
      <v-main>
        <Header v-if="authUser && !mustChangePassword">
        </Header>
        <v-scroll-x-transition v-if="!authUser || (authUser && mustChangePassword)" mode="out-in" hide-on-leave>
            <router-view></router-view>
        </v-scroll-x-transition>
        <!-- <v-scroll-x-transition mode="out-in" hide-on-leave>
            <router-view></router-view>
        </v-scroll-x-transition> -->
      </v-main>
      <div id="main-loader" class="background-loader">
        <span class="loader"></span>
      </div>
      <div id="loading" class="loading">
        <div class="lds-ripple"><div></div><div></div></div>
      </div>
      <FlashMessage class="vue_flash_message_container" :position="'right top'"/>
  </div>
</template>

<style>
  .loading {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #00000070;
    z-index: 999;
    top: 0;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 150px;
    top: 40%;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #47007e;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 75px;
      left: 75px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 75px;
      left: 75px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 75px;
      left: 75px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 150px;
      height: 150px;
      opacity: 0;
    }
  }
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */

.portal-container {
  margin-top: 5rem;
}

html { overflow-y: auto }
.background-loader {
  display: none;
  background-color: #FFFFFF;
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 100%;
}
.loader {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 45%;
  border-radius: 50%;
  color: #691b9d;
  animation: fill 1s ease-in infinite alternate;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 48px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

@media (max-width: 599.9px){
  .loader {
    left: 48% !important;
  }
}

.loader::after {
  left: auto;
  right: 48px;
  animation-duration: 1.1s;
}

@keyframes fill {
 0% {  box-shadow: 0 0 0 2px inset }
 100%{ box-shadow: 0 0 0 10px inset }
}

.vue_flash_message_container > div {
  z-index: 9999999999;
  padding: .5rem 2rem;
}

._vue-flash-msg-body._vue-flash-msg-body_warning {
  background-color: rgb(253, 240, 215) !important;
  border: 1px solid rgb(244, 170, 27) !important;
  color: rgb(198 131 0) !important
}
._vue-flash-msg-body._vue-flash-msg-body_success {
  background-color: #D4F1E0 !important;
  border: 1px solid #01947a !important;
  color: #01947a !important
}
._vue-flash-msg-body._vue-flash-msg-body_error {
  background-color: #FFC8CD !important;
  border: 1px solid #BC0A0E !important;
  color: #BC0A0E !important
}
  
</style>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header";
/* import Header2 from "@/components/Header2"; */

export default {
  name: "App",
  components: {
    Header,
  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin", "mustChangePassword"]),
  },
};
</script>