import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as message from "@/store/modules/Message";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  /* plugins:[
    createPersistedState()
  ], */
  modules: {
    auth,
    user,
    message,
  },
});