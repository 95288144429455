import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import mustChangePassword from "@/middleware/mustChangePassword";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login',
    name: "home",
    meta: { middleware: [guest] },
    component: () => import("../views/Home"),
  },
  /* {
    path: "/",
    name: "home",
    meta: { middleware: [guest] },
    component: () => import("../views/Login"),
  }, */
  {
    path: "/change-password",
    name: "change-password",
    meta: { middleware: [auth, mustChangePassword] },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/ChangePassword"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { middleware: [auth, mustChangePassword] },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard"),
  },
  /* {
    path: "/manager",
    name: "manager",
    meta: { middleware: [auth] },
    children: [
      { path: '/sections', name: 'sections', component: () => import("../views/Sections") },
    ],
  }, */
  {
    path: "/manager/sections",
    name: "sections",
    meta: { middleware: [auth, mustChangePassword] },
    component: () =>
      import("../views/Sections"),
  },
  {
    path: "/manager/categories",
    name: "categories",
    meta: { middleware: [auth, mustChangePassword] },
    component: () =>
      import("../views/Categories"),
  },
  {
    path: "/manager/products",
    name: "products",
    meta: { middleware: [auth, mustChangePassword] },
    component: () =>
      import("../views/Products"),
  },
  {
    path: "/user",
    name: "user",
    meta: { middleware: [auth, mustChangePassword] },
    component: () => import(/* webpackChunkName: "user" */ "../views/User"),
  },
  {
    path: "/users",
    name: "users",
    meta: { middleware: [auth, mustChangePassword, admin] },
    component: () => import(/* webpackChunkName: "users" */ "../views/Admin/Users"),
  },
  {
    path: "/login",
    name: "login",
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
  },
  {
    path: "/register",
    name: "register",
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: { middleware: [guest] },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword"
      ),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  /* scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  } */
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };
  console.log('middleware');
  console.log(middleware);
  if (!middleware) {
    console.log('no hay middleware')
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;