import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* import "@/assets/css/main.css"; */
import vuetify from './plugins/vuetify'
import * as API from "@/services/API";
import FlashMessage from '@smartweb/vue-flash-message';

/* import axios from "axios"; */


window.axios = API.apiClient;

/* window.axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  
  withCredentials: true, // required to handle the CSRF token
}); */

Vue.config.productionTip = false;

Vue.use(FlashMessage, {
  strategy: 'multiple'
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
